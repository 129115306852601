export default class PnUObjNew {

    constructor(kit, kit_id,kit_name, CO2, Edhw, Edhw_sol, Efan, Ep_nren, PEd_PEc, RER, TAC, TEc, TEd,
          nZEB, pmb_outside_class_B,u_r_value_wall, u_value_window,modeller, site)
          {
            this.kit=kit;
            this.kit_id=kit_id;
            this.kit_name=kit_name;
            this.CO2=CO2;
            this.Edhw=Edhw;
            this.Edhw_sol=Edhw_sol;
            this.Efan=Efan;
            this.Ep_nren=Ep_nren;
            this.PEd_PEc=PEd_PEc;
            this.RER=RER;
            this.TAC=TAC;
            this.TEc=TEc;
            this.TEd=TEd;
            this.nZEB=nZEB;
            this.pmb_outside_class_B=pmb_outside_class_B;
            this.u_r_value_wall=u_r_value_wall;
            this.u_value_window=u_value_window;
            this.modeller=modeller;
            this.site=site;
          }

}