import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import './scss/Login.scss';
import logo from './img/logo.png';
import { Button } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { VpnKey } from "@mui/icons-material";

function LoginPage() {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  return (
    <div className="login_staff">
      <div className="centered_con">
        {!!keycloak.authenticated?<div className="beautify"> Welcome {keycloak.tokenParsed.preferred_username}</div>:<div className="beautify">Welcome to MODEST-LYSIS dashboards</div>}

        {!keycloak.authenticated && (
          <Button variant="contained" sx={{backgroundColor:"rgb(56, 191, 191)"}} onClick={() => keycloak.login()} startIcon={< VpnKey/>}>Login</Button>

        )}

        {!!keycloak.authenticated && (
          <>
            {/* <span style={{marginTop:'-400px', fontWeight:'600',fontSize:'20px',textShadow: '2px 2px #3a3a3a'}}>Welcome {keycloak.tokenParsed.preferred_username}</span> */}
            <Button variant="contained" sx={{backgroundColor:"rgb(56, 191, 191)"}} onClick={() => navigate("/home")} startIcon={<LoginIcon />} >DashBoard</Button>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
