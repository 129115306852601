import PnUObjNew from "./PnUObjNew";

export default class PnUObjHybrid extends PnUObjNew {
    constructor(kit, kit_id, kit_name,operation_mode, pv_area, shading, thermal_insulation, tiles, window_frame, window_glazing,
        CO2, Edhw, Edhw_sol, Efan, Ep_nren,
        PEd_PEc, RER, TAC, TEc, TEd, nZEB,
        pmb_outside_class_B, u_r_value_wall, u_value_window, modeller, site) 
        {
        super( kit_id, kit, kit_name,
            CO2, Edhw, Edhw_sol, Efan, Ep_nren,
            PEd_PEc, RER, TAC, TEc, TEd, nZEB,
            pmb_outside_class_B, u_r_value_wall, u_value_window, modeller, site)

            this.operation_mode=operation_mode;
            this.pv_area=pv_area;
            this.thermal_insulation=thermal_insulation;
            this.window_frame=window_frame;
            this.window_glazing=window_glazing;
            this.tiles=tiles;
            this.shading=shading;
        }
}