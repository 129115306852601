import Home from './Home';
import './scss/App.scss';
import LoginPage from './LoginPage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./comp/Keycloak";
import PrivateRoute from './helpers/PrivateRoute';
import Modest from './Modest';


function App() {
  return (
    <div className="App">
      <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
          <Routes>
            {/* <Route path='/home' element={<Home/>}/> */}
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/modest" element={<PrivateRoute><Modest /></PrivateRoute>} />
            <Route path="/" element={<LoginPage />} />
          </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
