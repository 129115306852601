// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
  border-radius: 10px;
  margin: 5px 10px;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/scss/Table.scss"],"names":[],"mappings":"AAAA;EACI,sDAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AACJ","sourcesContent":[".table {\n    box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);\n    border-radius: 10px;\n    margin: 5px 10px;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
