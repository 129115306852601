import "../scss/InfoPnUCard.scss";
import ClearIcon from '@mui/icons-material/Clear';
function InfoPnUCard({infocard_text, removeCard}) {
    return (
        <div className="infocard">
            <div className="infocard_text">
                {infocard_text}
            </div>
            <div className="infocard_icon" onClick={removeCard}>
                <ClearIcon/>
            </div>
        </div>
    );
}

export default InfoPnUCard;