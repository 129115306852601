

export default class PnUObjSmart {
    constructor(kit_id, kit, kit_name, insulation_thickness, pv_area, window_glazing, window_frame,smartwall_type,   Edhw, Edhw_sol, Ep_nren, PEd_PEc, RER, TAC, TEc, TEd,
        nZEB, pmb_outside_class_B, u_r_value_wall, u_value_window, modeller, site ) {
        this.kit_id = kit_id;
        this.kit = kit;

        this.kit_name = kit_name;


        this.insulation_thickness = insulation_thickness;
        this.pv_area = pv_area;
        this.window_glazing = window_glazing;
        this.window_frame = window_frame;
        this.smartwall_type = smartwall_type;

        this.Edhw = Edhw;
        this.Edhw_sol = Edhw_sol;

        this.Ep_nren = Ep_nren;
        this.PEd_PEc = PEd_PEc;
        this.RER = RER;
        this.TAC = TAC;
        this.TEc = TEc;
        this.TEd = TEd;
        this.nZEB = nZEB;
        this.pmb_outside_class_B = pmb_outside_class_B;
        this.u_r_value_wall = u_r_value_wall;
        this.u_value_window = u_value_window;
        this.modeller = modeller;
        this.site = site;

        
    }
}