import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
  /*Removing React.StrintMode Solves the repeated Fetching While Quering  Solution tou Gonatou but for now it works fine----In the end JSOC*/
);


