export default class MaObj {
    constructor(id,table, site, _field, _measurement, apartment, _value, timestamp) {
      this.id=id;
      this.table = table;
      this.site = site;
      this._field = _field;
      this._measurement=_measurement;
      this.apartment=apartment;
      this._value=_value;
      this.timestamp=timestamp;
    }
  }