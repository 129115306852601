// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/logo-removebg-preview.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_staff {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.login_staff .centered_con {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 85%;
  height: 90%;
  border-radius: 10px;
  box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat, linear-gradient(rgb(56, 191, 191), rgb(255, 255, 255));
}
.login_staff .centered_con .beautify {
  text-shadow: 2px 2px #3a3a3a;
  flex: 10 1 100%;
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  flex-basis: 100%;
  margin-bottom: 200px;
}
.login_staff .centered_con span {
  flex: 1 1 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/scss/Login.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAAJ;AAII;EACI,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;EACA,sDAAA;EACA,4HAAA;AAFR;AAKQ;EAEI,4BAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;AAJZ;AAOQ;EAEI,cAAA;EACA,kBAAA;AANZ","sourcesContent":[".login_staff{\n    display: flex;\n    //background-color: rgb(208, 245, 245);\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    //background:url(\"../img/landscape-1844226_1280.png\");\n    \n    \n    .centered_con{\n        color:white;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex-wrap: wrap;\n        width: 85%;\n        height:90%;\n        border-radius: 10px;\n        box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);\n        background: url(\"../img/logo-removebg-preview.png\") center no-repeat, linear-gradient(rgb(56, 191, 191), rgb(255, 255, 255));\n        \n        \n        .beautify\n        {\n            text-shadow: 2px 2px #3a3a3a;\n            flex: 10 100%;\n            font-weight: 700;\n            font-size: 40px;\n            text-align: center;\n            flex-basis: 100%;\n            margin-bottom: 200px;\n        }\n        \n        span {\n            \n            flex: 1 100%;\n            text-align: center;\n            \n        }\n        \n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
