import PnUObjNew from "./PnUObjNew";

export default class PnUObjConEx extends PnUObjNew {
    constructor(kit, kit_id,   kit_name, insulation_thickness, pv_area, conf_site, wall_u_value, CO2, Edhw, Edhw_sol, Efan, Ep_nren, PEd_PEc, RER, TAC, TEc, TEd,
        nZEB, pmb_outside_class_B,u_r_value_wall, u_value_window,modeller, site)
    {
        super(kit_id, kit,  kit_name,
            CO2, Edhw, Edhw_sol, Efan, Ep_nren,
            PEd_PEc, RER, TAC, TEc, TEd, nZEB,
            pmb_outside_class_B, u_r_value_wall, u_value_window, modeller, site)
        //ConEx
        this.conf_site=conf_site;
        this.insulation_thickness=insulation_thickness;
        this.wall_u_value=wall_u_value;
        this.pv_area=pv_area;
    }
}