// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart {
  font-family: Georgia, serif;
  color: rgb(108, 108, 108);
  display: flex;
  flex-wrap: wrap;
  box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);
  border-radius: 10px;
  margin: 10px 10px;
  border-radius: 10px;
  justify-content: space-evenly;
  padding-left: 7px;
  align-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/scss/Chart.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,yBAAA;EACA,aAAA;EACA,eAAA;EACA,sDAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,6BAAA;EACA,iBAAA;EACA,2BAAA;AACJ","sourcesContent":[".chart {\n    font-family: Georgia, serif;\n    color: rgb(108, 108, 108);\n    display: flex;\n    flex-wrap: wrap;\n    box-shadow: 3px 3px 17px 1px rgba(130, 130, 130, 0.83);\n    border-radius: 10px;\n    margin: 10px 10px ;\n    border-radius: 10px;\n    justify-content:  space-evenly;\n    padding-left: 7px;\n    align-content: space-around;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
