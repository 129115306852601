// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infocard {
  display: flex;
  width: 55%;
  height: 50px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  margin-left: 7px;
  margin-top: 7px;
  box-shadow: 0px 0px 3px 1px rgb(172, 223, 223);
}
.infocard .infocard_text {
  margin-left: 5px;
  display: flex;
  text-align: center;
}
.infocard .infocard_icon {
  font-size: 10px;
  box-shadow: -0.5px 0px 2px 2px rgba(239, 19, 19, 0.49);
  color: white;
  background-color: rgb(245, 75, 75);
  border-radius: 50%;
  padding: 2px 1.5px 1px 2px;
  align-self: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/scss/InfoPnUCard.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,8CAAA;AAAJ;AACI;EACI,gBAAA;EACA,aAAA;EACA,kBAAA;AACR;AACI;EACI,eAAA;EACA,sDAAA;EACA,YAAA;EACA,kCAAA;EACA,kBAAA;EACA,0BAAA;EACA,kBAAA;EACA,eAAA;AACR","sourcesContent":[".infocard\n{\n    display: flex;\n    width: 55%;\n    height: 50px;\n    padding-left: 7px;\n    padding-right: 7px;\n    padding-top: 7px;\n    padding-bottom: 7px;\n    justify-content: space-between;\n    align-items: center;\n    border-radius: 10px;\n    margin-left:7px;\n    margin-top: 7px;\n    box-shadow: 0px 0px 3px 1px rgb(172, 223, 223);\n    .infocard_text{\n        margin-left: 5px;\n        display: flex;\n        text-align: center;\n    }\n    .infocard_icon{\n        font-size: 10px;\n        box-shadow: -0.5px 0px 2px 2px rgba(239,19,19,0.49);\n        color:white;\n        background-color: rgb(245, 75, 75);\n        border-radius: 50%;\n        padding:2px 1.5px 1px 2px;\n        align-self: center;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
