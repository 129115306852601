import React, { useState } from "react";
//import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'; to be used in case of lightweiht Recharts
import MaObjieq from "../objects/MaObjectIEQ";
import "../scss/Chart.scss";
import moment from 'moment';
import Chart from "react-apexcharts";

function ChartIeq({ theArray, chartfetch, weRender }) {


    let ieq = "";
    let number_of_tables = 0;
    let color = "";
    const list = [];
    const rendered_result = [];
    const filtered = [];
    if (chartfetch === "OK") {
        if (theArray[0].zone === undefined) {
            ieq = "IEQ";
            console.log("ITS UNDIFIEND" + ieq);
        }
        else {
            ieq = "NOT";
            console.log("WE HAVE A ZONE" + ieq);
        }

        for (let i = 0; i < theArray.length; i++) {
            list.push(new MaObjieq(theArray[i].id, theArray[i].table, theArray[i].site, theArray[i]._field, theArray[i]._measurement, theArray[i].apartment, theArray[i].zone, theArray[i]._value, moment(theArray[i].timestamp).format('yyyy-MM-DD')));
            number_of_tables = theArray[i].table;
        }
        for (let x = 0; x <= number_of_tables; x++) {
            filtered[x] = list.filter(obj => { return obj.table === x });//different figure based on area (apartement , building etc)

        }

    }


    for (let i = 0; i < filtered.length; i++) {
        let vlaues = [];
        
        let timestampsForApex = [];
        for (let x = 0; x < filtered[i].length; x++) {
            vlaues.push(filtered[i][x]._value);
            parseFloat(vlaues[x]).toFixed(2);       
            timestampsForApex.push(filtered[i][x].timestamp);
        }
        // rendered_result.push(
        //     <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
        //         <Chart
        //             options={{ annotations:{yaxis:[{y:100, y2:420, fillColor: "#f0b1b6",label:{text: "Threshold"}}]}, stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true }}, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2 , title:{text:"CO2 (ppm)" }} }}
        //             series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
        //             width={650}
        //             height={325}
        //         />
        //     </div>)
        
        //if (weRender==="OK"){color="#"+Math.floor(Math.random()*16777215).toString(16);}

        // ********************************************************if Statement to check which type of measurment we look at in order to change the Threshold values and the Yaxis Title (label)********************************************
        if (filtered[i][0]._field==="CO2")
        {
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                    // annotations:{yaxis:[{y:100, y2:420, fillColor: "#f0b1b6",label:{text: "Threshold"}}]},
                        options={{ stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true }}, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2 , title:{text:"CO2 (ppm)" }} }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="Ev")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Ambient light (lx)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="RH")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Relative humidity (%)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="Temp")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Temperature (Celsius)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="VOC")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"VOC (ppm)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="Eelec")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, autoScaleYaxis: true ,title:{text:"Electric Energy Export (KWh)"} } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="Edhw_e")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Domestic hot water demand (KWh)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="E_comm")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Energy common zones (KWh)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="THGc")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Heating gas consumption (KWh)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="THGd")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Heating gas demand (KWh)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
         
        if (filtered[i][0]._field==="MRT")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Mean Radiant Temp. (Celsius)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="WS")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field + " " + filtered[i][0].apartment, align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Wind Speed (km/h)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }


        ///WEATHER STAFF
        if (filtered[i][0]._field==="Temp_out")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field , align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Temperature (Celsius)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="SolRad")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field , align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Solar Radiation (W/m\u00B2)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="RH_out")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field , align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Relative Humidity (%)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="Ev_out")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field , align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"Illuminance (lx)" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        if (filtered[i][0]._field==="UV_idx")
        { 
            rendered_result.push(
                <div style={{ marginTop: "10px", minΗeight: "500px" }}> 
                    <Chart
                        options={{  stroke: {curve: 'smooth',show: true, colors: ["#38bfbf"], width: 2 }, fill: { colors: ["#38bfbf"],opacity:0.2}, title: { text: filtered[i][0]._field , align: "left" }, subtitle: { text: filtered[i][0].zone, align: "left", style: { fontSize: "15px", fontWeight: "bold" } }, xaxis: { categories: timestampsForApex, labels: { hideOverlappingLabels: true } }, dataLabels: { enabled: false }, yaxis: { decimalsInFloat:2, title:{text:"UV Index" } } }}
                        series={[{type:"area", name: filtered[i][0]._field, data: vlaues }]}
                        width={650}
                        height={325}
                    />
                </div>)
        }
        
    }

    return (<div className="chart">
        {rendered_result}
    </div>);
}

export default ChartIeq;


{/* Can BE COmmented out In case we want to implement the lightweight Recharts Library Instead */}

                {/* {ieq!=="IEQ"?<>Apartment: {filtered[i][0].apartment}</>:<></>}
                <ResponsiveContainer width={650} aspect={2 / 1}>
                <AreaChart width={730} height={350} data={filtered[i]}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}>
                    <XAxis dataKey="timestamp" stroke="gray" height={60} dy={3} angle={-10} label={{ value: 'timestamp', position: 'center'}} style={{fontSize:'0.6rem'}}/>
                    {ieq!=="IEQ"?<YAxis stroke="gray" label={{ value: `${filtered[i][0]._field} ${filtered[i][0].zone}`, dx: -23, angle: -90, position: 'inside' }} style={{fontSize:'0.6rem'}}/>:<YAxis stroke="gray" label={{ value: `${filtered[i][0]._field}  ${filtered[i][0].apartment}`, dx: -23, angle: -90, position: 'inside' }} style={{fontSize:'0.6rem'}}/>}
                    <CartesianGrid strokeDasharray="4 4" />
                    <Tooltip />             
                    <Area type="monotone" dataKey="_value" stroke="grey"  fillOpacity={0.1} fill={color} /> 
                </AreaChart>
                </ResponsiveContainer> */}

                {/* Can BE COmmented out In case we want to implement the heavyweigth but better Apex-Charts Library Instead */}