import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import BuildIcon from '@mui/icons-material/Build';
import logo_letter from './img/logo_letter.jpg';
import logo_house from './img/logo_house.jpg';
import LogoutIcon from '@mui/icons-material/Logout';
import './scss/Modest.scss';
import '@inovua/reactdatagrid-community/index.css'
import { Button } from 'primereact/button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
//*************** */ Prime REACT PART **************//
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import Swal from 'sweetalert2'
import 'primereact/resources/themes/lara-light-teal/theme.css';//GOOD FOR PLURALLLL
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import { Ripple } from 'primereact/ripple';
import { MultiSelect } from 'primereact/multiselect';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SmartWall from "./trials/SmartWall";
import InfoPnUCard from "./trials/InfoPnUCard";
import PnUObjNew from "./objects/PnUObjNew";
import PnUObjHybrid from "./objects/PnUObjHybrid";
import PnUObjConEx from "./objects/PnUObjConEx";
import { Hidden } from "@mui/material";
import PnUObjSmart from "./objects/PnUObjSmart";
import { string } from "@inovua/reactdatagrid-community/filterTypes";
//**************************UnUsed Libraries************************************/
// import * as xlsx from 'xlsx';
// import excelFile from './simplerJordi_Data - Copy-Round.xlsx';
// import ReactDataGrid from '@inovua/reactdatagrid-community'
// import Tooltip from '@mui/material/Tooltip';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import MaObjieq from "./objects/MaObjectIEQ";
// import MaObj from "./objects/MaObj";
// import { read, utils, excel } from 'xlsx';
//import 'primereact/resources/themes/lara-light-indigo/theme.css';
//import Button from '@inovua/reactdatagrid-community/packages/Button';
// import CoupledStaff3D from "./trials/CoupledStaff3D";
//import "primereact/resources/themes/fluent-light/theme.css";
//import "primereact/resources/themes/mdc-light-indigo/theme.css";  //theme


const SEPARATOR = ',';


function Modest() {
    const [data, setData] = useState([]);

    const sites = ["None", "TERRASSA", "KASAVA", "Berlin", "VVV"];
    const modellers = ["None", "IREC", "SPF", "NTUA"];
    const kits = ["None", "HybridWall", "ConExWall", "SmartWall"];

    //***********PRIME REACT PART***********/

    const columnInitialization =
        [
            { field: 'kit_id', header: 'ID', dataType: 'numeric' },
            { field: 'kit', header: 'kit' },
            { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
            { field: 'operation_mode', header: 'operation mode' },
            { field: 'pv_area', header: 'PV area' },
            { field: 'shading', header: 'shading' },
            { field: 'thermal_insulation', header: 'thermal insulation' },
            { field: 'tiles', header: 'tiles' },
            { field: 'window_frame', header: 'window frame' },
            { field: 'window_glazing', header: 'window glazing' },
            { field: 'insulation_thickness', header: 'insulation thickness' },
            { field: 'conf_site', header: 'configuration site' },
            { field: 'wall_u_value', header: 'Wall U value' },
            { field: 'CO2', header: 'CO2', headerTooltip: "Air quality-CO2 index (ppm)" },
            { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
            { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
            { field: 'Efan', header: 'Efan', dataType: 'numeric', headerTooltip: "Electric consumption of mechanical ventilation (kWh/m\u00B2)" },
            { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
            { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
            { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
            { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
            { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
            { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
            { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
            { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
            { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
            { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
            { field: 'modeller', header: 'modeller' },
            { field: 'site', header: 'site' },
        ]

    const columnsAll = [
        { field: 'kit_id', header: 'ID', dataType: 'numeric' },
        { field: 'kit', header: 'kit' },
        { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
        { field: 'CO2', header: 'CO2', headerTooltip: "Air quality-CO2 index (ppm)", visibility: "hidden", display: "none" },
        { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
        { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
        { field: 'Efan', header: 'Efan', dataType: 'numeric', headerTooltip: "Electric consumption of mechanical ventilation (kWh/m\u00B2)" },
        { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
        { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
        { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
        { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
        { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
        { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
        { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
        { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
        { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
        { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
        { field: 'modeller', header: 'modeller' },
        { field: 'site', header: 'site' },
    ];

    const columnsHybridWall = [
        { field: 'kit_id', header: 'ID', dataType: 'numeric' },
        { field: 'kit', header: 'kit' },
        { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
        { field: 'operation_mode', header: 'operation mode' },
        { field: 'pv_area', header: 'PV area' },
        { field: 'shading', header: 'shading' },
        { field: 'thermal_insulation', header: 'thermal insulation' },
        { field: 'tiles', header: 'tiles' },
        { field: 'window_frame', header: 'window frame' },
        { field: 'window_glazing', header: 'window glazing' },
        { field: 'CO2', header: 'CO2', headerTooltip: "Air quality-CO2 index (ppm)" },
        { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
        { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
        { field: 'Efan', header: 'Efan', dataType: 'numeric', headerTooltip: "Electric consumption of mechanical ventilation (kWh/m\u00B2)" },
        { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
        { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
        { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
        { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
        { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
        { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
        { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
        { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
        { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
        { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
        { field: 'modeller', header: 'modeller' },
        { field: 'site', header: 'site' },

    ];

    const columnConEx = [
        { field: 'kit_id', header: 'ID', dataType: 'numeric' },
        { field: 'kit', header: 'kit' },
        { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
        { field: 'insulation_thickness', header: 'insulation thickness' },
        { field: 'pv_area', header: 'PV area' },
        { field: 'conf_site', header: 'configuration site', visibility: "hidden", display: "none" },
        { field: 'wall_u_value', header: 'Wall U value' },
        { field: 'CO2', header: 'CO2', headerTooltip: "Air quality-CO2 index (ppm)" },
        { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
        { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
        { field: 'Efan', header: 'Efan', dataType: 'numeric', headerTooltip: "Electric consumption of mechanical ventilation (kWh/m\u00B2)" },
        { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
        { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
        { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
        { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
        { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
        { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
        { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
        { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
        { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
        { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
        { field: 'modeller', header: 'modeller' },
        { field: 'site', header: 'site' },
    ];
    const columnSmart = [
        { field: 'kit_id', header: 'ID', dataType: 'numeric' },
        { field: 'kit', header: 'kit' },
        { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
        { field: 'insulation_thickness', header: 'insulation thickness' },
        { field: 'pv_area', header: 'PV area' },
        { field: 'window_glazing', header: 'window glazing ' },
        { field: 'window_frame', header: 'window frame' },
        { field: 'smartwall_type', header: 'smartwall type' },
        { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
        { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
        { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
        { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
        { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
        { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
        { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
        { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
        { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
        { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
        { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
        { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
        { field: 'modeller', header: 'modeller' },
        { field: 'site', header: 'site' },
    ];


    const [rows1, setRows1] = useState(20);
    const [first1, setFirst1] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedColumns, setSelectedColumns] = useState(columnInitialization);
    const [colLengthConfig, setColLengthConfig] = useState(4);
    const [selectedProduct, setSelectedProduct] = useState();
    const [tableToRender, setTableToRender] = useState("ALL");
    const [radioButton, setRadioButton] = useState("None");

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        setRows1(event.rows);
        setCurrentPage(event.page + 1);
    }

    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Previous</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Next</span>
                    <Ripple />
                </button>
            )
        },
    };

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'my_data');
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }





    //***********PRIME REACT PART***********/

    function alertingOnClick(e) {

        if (e.value.kit === "HybridWall") {
            Swal.fire({
                title: 'PnU Configurations HybridWall',
                html:
                    "Operation Mode: " + e.value.operation_mode +
                    "<br>PV Area : " + e.value.pv_area +
                    "<br>Thermal Insulation: " + e.value.thermal_insulation +
                    "<br>Window Frame: " + e.value.window_frame +
                    "<br>Window Glazing: " + e.value.window_glazing +
                    "<br> Shading: " + e.value.shading +
                    "<br> Tiles: " + e.value.tiles,
                icon: 'success',
            })
        }
        if (e.value.kit === "ConExWall") {
            Swal.fire({
                title: 'PnU Configurations ConExWall',
                html:
                    "<br>PV Area : " + e.value.pv_area +
                    "<br>Insulation Thickness: " + e.value.insulation_thickness +
                    "<br>Wall U Value: " + e.value.wall_u_value,
                icon: 'success',
            })
        }
        if (e.value.kit === "SmartWall") {
            Swal.fire({
                title: 'PnU Configurations SmartWall',
                html:
                    "<br>PV Area : " + e.value.pv_area +
                    "<br>Insulation Thickness: " + e.value.insulation_thickness +
                    "<br>Window Frame: " + e.value.window_frame +
                    "<br>SmartWall Type: " + e.value.smartwall_type +
                    "<br>Window Glazing: " + e.value.window_glazing,
                icon: 'success',
            })
        }
        if (radioButton === "ALL") {
            const text = e.value.kit_name;
            const splitText = text.split("_");
            if (e.value.kit === "HybridWall") {
                Swal.fire({
                    title: 'PnU Configurations HybridWall',
                    html:
                        "Operation Mode: " + splitText[0] +
                        "<br>PV Area : " + splitText[1] +
                        "<br>Thermal Insulation: " + splitText[2] +
                        "<br>Window Frame: " + splitText[3] +
                        "<br>Window Glazing: " + splitText[4] +
                        "<br> Shading: " + splitText[5] +
                        "<br> Tiles: " + splitText[6],
                    icon: 'success',
                })
            }
            if (e.value.kit === "ConExWall") {
                Swal.fire({
                    title: 'PnU Configurations ConExWall',
                    html:
                        "<br>PV Area : " + splitText[2] +
                        "<br>Wall U Value: " + splitText[1],
                    icon: 'success',
                })
            }
            if (e.value.kit === "SmartWall") {
                Swal.fire({
                    title: 'PnU Configurations SmartWall',
                html:
                    "<br>PV Area : " + splitText[4] +
                    "<br>Insulation Thickness: " + splitText[1] +
                    "<br>Window Frame: " + splitText[2] +
                    "<br>SmartWall Type: " + splitText[0] +
                    "<br>Window Glazing: " + splitText[3],
                icon: 'success',
                })
            }
        }

    }


    let pnuData = [];
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();
    const date = new Date();

    const [gridRef, setGridRef] = useState(null);

    const downloadBlob = (blob, fileName = `MODEST_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}.csv`) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportCSV = () => {
        const columns = gridRef.current.visibleColumns;
        const header = columns.map((c) => c.name).join(SEPARATOR);
        const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));
        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
        downloadBlob(blob);
    };



    //****************************************** API+ Fetch AND COMMUNICATION For DIFFERENT ENDPOINTS **************************************/
    //Fetch Function to retrieve ALL from API
    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <MultiSelect display="chip" value={selectedColumns} options={columnInitialization} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em' }} />
                <Button tooltip="Download Data" style={{ margin: "2px 2px 1px 10px" }} type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" />
            </div>
        )
    }



    const onColumnToggle = (event) => {
        let selectedColumnsforToggle = event.value;
        let orderedSelectedColumns = columnInitialization.filter(col => selectedColumnsforToggle.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }

    const header1 = renderHeader1();


    const columnComponents = selectedColumns.map(col => {
        return <Column headerTooltip={col.headerTooltip} style={col.style} headerTooltipOptions={{ position: 'top' }} key={col.field} field={col.field} header={col.header} dataType={col.dataType} filter filterElement={col.value} sortable />
    });

    const headerGroup =
        (
            <ColumnGroup>
                <Row>
                    <Column header="General Kit Information" colSpan={3} />
                    <Column header="PnU Configurations" colSpan={colLengthConfig} />
                    <Column header="KPI Fields" colSpan={16} />
                </Row>
                <Row>
                    {columnComponents}
                </Row>
            </ColumnGroup>
        )
    const headerGroupAll =
        (
            <ColumnGroup>
                <Row>
                    <Column header="General Kit Information" colSpan={3} />
                    <Column header="KPI Fields" colSpan={16} />
                </Row>
                <Row>
                    {columnComponents}
                </Row>
            </ColumnGroup>
        )


    //FETCH ALL -- NO INPUT IN DROPDOWNS
    async function fetchAll() {
        setData([]);
        const fetched = await fetch(`/pnus`);
        const pnu = await fetched.json();

        setColLengthConfig(0);
        if (pnu.length > 0) {
            
            for (let i = 0; i < pnu.length; i++) {
                let Efan;
                if (typeof pnu[i]['kpi'].Efan==='string')
                {
                    Efan=pnu[i]['kpi'].Efan;
                }
                else{
                    Efan=Number(pnu[i]['kpi'].TEd.toFixed(2));
                }
                pnuData.push(new PnUObjNew(pnu[i].kit, pnu[i].kit_config_id, pnu[i].kit_config_name, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Efan,
                    Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB.toString(), Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
            }
            setData([...pnuData]);
        }
    }


    //FETCH By KIT ONLY
    async function fetchByKit(kit) {
        setData([]);
        const fetched = await fetch(`/pnus?kit=${kit}`);
        const pnu = await fetched.json();
        if (kit === "HybridWall") {
            setColLengthConfig(7);
            setSelectedColumns(columnsHybridWall)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjHybrid(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].operation_mode, pnu[i]['config'].pv_area, pnu[i]['config'].shading, pnu[i]['config'].thermal_insulation, pnu[i]['config'].tiles, pnu[i]['config'].window_frame, pnu[i]['config'].window_glazing, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Number(pnu[i]['kpi'].Efan.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (kit === "ConExWall") {
            setColLengthConfig(4);
            setSelectedColumns(columnConEx)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjConEx(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].site, pnu[i]['config'].wall_u_value, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Number(pnu[i]['kpi'].Efan.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (kit === "SmartWall") {
            setColLengthConfig(5);
            setSelectedColumns(columnSmart)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjSmart(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].window_glazing, pnu[i]['config'].window_frame, pnu[i]['config'].smartwall_type, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }

        }
    }

    async function fetchBySite(site) {
        setData([]);
        const fetched = await fetch(`/pnus?site=${site}`);
        const pnu = await fetched.json();
        if (site === "TERRASSA") {
            setColLengthConfig(7);
            setSelectedColumns(columnsHybridWall)
            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjHybrid(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].operation_mode, pnu[i]['config'].pv_area, pnu[i]['config'].shading, pnu[i]['config'].thermal_insulation, pnu[i]['config'].tiles, pnu[i]['config'].window_frame, pnu[i]['config'].window_glazing, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Number(pnu[i]['kpi'].Efan.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (site === "KASAVA") {
            setColLengthConfig(4);
            setSelectedColumns(columnConEx)
            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjConEx(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].site, pnu[i]['config'].wall_u_value, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Number(pnu[i]['kpi'].Efan.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (site === "Berlin") {
            setColLengthConfig(5);
            setSelectedColumns(columnSmart)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjSmart(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].window_glazing, pnu[i]['config'].window_frame, pnu[i]['config'].smartwall_type, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (site === "VVV") {
            setColLengthConfig(5);
            setSelectedColumns(columnSmart)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjSmart(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].window_glazing, pnu[i]['config'].window_frame, pnu[i]['config'].smartwall_type, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        // if (data.length === 0) {
        //     console.log("Is empty");
        // }
    }
    //SELECTORS FUNCTIONS
    const [selectedSite, setSelectedSite] = useState("None");
    const [selectedKit, setSelectedKit] = useState("None");

    function radioButtonChecked(e) {
        setRadioButton(e.target.value);
        if (e.target.value === "ALL") {
            setSelectedKit("None")
            setSelectedSite("None")
        }
        if (e.target.value === "KIT") {
            setSelectedSite("None")
        }
        if (e.target.value === "SITE") {
            setSelectedKit("None")
        }
    }

    function selectSite(e) {
        setSelectedSite(e.target.value);
    }
    function selectKit(e) {
        setSelectedKit(e.target.value);
    }


    function fetchGeneral() {

        if (selectedSite === "None" && selectedKit === "None") {
            fetchAll();
            setSelectedColumns(columnsAll);
            setTableToRender("ALL");
        }
        if (selectedSite !== "None") {
            fetchBySite(selectedSite);
            setTableToRender("KIT");
        }
        if (selectedKit !== "None") {
            fetchByKit(selectedKit);
            setTableToRender("KIT");
        }

    }


    useEffect(() => {
        if (window.innerWidth < 1300)
            setRows1(10)
        if (window.innerWidth >= 1300)
            setRows1(20)

    }, [rows1])



    //******* Manage the Height and Rows of the Table based on the Screen Size (Who did it Bi@@@h!) ********/
    useEffect(() => {//FUCKING IMPORTANT
        setData(data)
    }, [data])


    //*********************************SMART WALL STAFF**********************************/
    const [isActive, setIsActive] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);
    const [isActive5, setIsActive5] = useState(false);
    const [isActive6, setIsActive6] = useState(false);
    const [radioLabel, setRadioLabel] = useState("Click on the Model")
    const [radioOption1, setRadioOption1] = useState("Option1")
    const [radioOption2, setRadioOption2] = useState("Option2")
    const [radioOption3, setRadioOption3] = useState("Option3")
    const [radioOption4, setRadioOption4] = useState("Option4")
    const [radioOption5, setRadioOption5] = useState("Option5")
    const [numberOfOptions, setnumberofOptions] = useState(0);
    const [radioChoise, setRadioChoise] = useState("");
    const [hide, setHide] = useState(false);

    function showMeTheClass(e) {
        setnumberofOptions(0);
        console.log(e.currentTarget.className);
        if (e.currentTarget.className === "shape cuboid-1 cub-1") {
            setIsActive(current => !current);
            setIsActive3(false)
            setIsActive4(false)
            setIsActive5(false)
            setIsActive6(false)
            setRadioLabel("Insulation Thickness")
            setRadioOption1("100mm")
            setRadioOption2("160mm")
            setRadioOption3("240mm")
            setnumberofOptions(3)
        }
        if (e.currentTarget.className === "shape cuboid-4 cub-4") {
            setIsActive4(current => !current);
            setIsActive(false)
            setIsActive3(false)
            setIsActive5(false)
            setIsActive6(false)
            setRadioLabel("Window Glass")
            setRadioOption1("Double")
            setRadioOption2("Triple")
            setnumberofOptions(2)

        }
        if (e.currentTarget.className === "shape cuboid-5 cub-5") {
            setIsActive5(current => !current);
            setIsActive(false)
            setIsActive3(false)
            setIsActive4(false)
            setIsActive6(false)
            setRadioLabel("Window Frame")
            setRadioOption1("PVC")
            setRadioOption2("Aluminium")
            setnumberofOptions(2)
        }
        if (e.currentTarget.className === "shape cuboid-6 cub-6") {
            setIsActive6(current => !current);
            setIsActive(false)
            setIsActive3(false)
            setIsActive5(false)
            setIsActive4(false)
            setRadioLabel("PV Area")
            setRadioOption1("0m\u00B2")
            setRadioOption2("10m\u00B2")
            setRadioOption3("20m\u00B2")
            setRadioOption4("30m\u00B2")
            setRadioOption5("40m\u00B2")
            setnumberofOptions(5)
        }

    }

    useEffect(() => {
        if (radioLabel === "Click on the Model") {
            setHide(false);
        }
        else {
            setHide(true);
        }
    }, [radioLabel])




    function handleRadioChange(e) {
        setRadioChoise(e.target.value);
    }

    useEffect(() => {

    }, [radioChoise])

    let newCardList = [];
    function removeCard(index) {
        const newCardList = cardList.filter((_, i) => i !== index);
        setCardList(newCardList);

        console.log(cardList);
    }
    ////This is the part where the Window is used
    //TODO -- Check whether the List of window Info (i.e.paxos insulation is unique)
    const [uniquePnU, setUniquePnU] = useState([]);
    const [cardList, setCardList] = useState([]);
    //On submit For 3D model
    function submit_label_choise() {
        console.log(radioLabel)
        setCardList(oldlist => [...oldlist, <InfoPnUCard infocard_text={radioLabel + ": " + radioChoise} removeCard={removeCard} />])
        setIsActive6(false)
        setIsActive(false)
        setIsActive3(false)
        setIsActive5(false)
        setIsActive4(false)
        setRadioLabel("Click on the Model")
        setnumberofOptions(0)
    }

    return (
        <div className="home_main_div">
            <div className="sidebar_unfetched">
                <div className="logo"><li><img src={logo_letter} alt={"plural_logo"} /><img src={logo_house} alt={"plural_logo"} /></li></div>
                <h5>MAIN</h5>
                <li onClick={() => navigate("/modest")} style={{ color: "white", backgroundColor: "rgba(4,188,183,255)" }}><BuildIcon className="mui_icon" style={{ color: "white" }} />MODEST</li>
                <li onClick={() => navigate("/home")}><EqualizerIcon className="mui_icon" />Site Monitoring</li>
                <h5>USER</h5>
                <li><AccountBoxIcon className="mui_icon" /> Profile</li>
                {!!keycloak.authenticated && (<li onClick={() => { navigate("/"); keycloak.logout() }}><LogoutIcon className="mui_icon" />Logout ({keycloak.tokenParsed.preferred_username})</li>)}
            </div>
            <div className="inner_home_div_modest">
                <div className="beautifyTheDiv">
                    {/* The below is used in order to Fetch Data from The KAFKA topic - IT works with Object (PnUObj) and it Used as Columns the "mycolumns" array above */}
                    <div className="button_div">
                        <div>
                            <label>Search by: </label>
                            <input type="radio" value="ALL" name="columns" onChange={(e) => radioButtonChecked(e)} /> All
                            <input type="radio" value="SITE" name="columns" onChange={(e) => radioButtonChecked(e)} /> Site
                            <input type="radio" value="KIT" name="columns" onChange={(e) => radioButtonChecked(e)} /> Kit
                            {radioButton === "KIT" ? <><label>Kit: </label>
                                <select onChange={selectKit}>
                                    {kits.map((kit) => (
                                        <option key={kit}>{kit}</option>))}
                                </select></> : <></>}
                            {radioButton === "SITE" ? <><label>Site: </label>
                                <select onChange={selectSite}>
                                    {sites.map((site) => (
                                        <option key={site}>{site}</option>))}
                                </select></> : <></>}
                        </div>

                        {radioButton !== "None" ? <button className="export_button" onClick={fetchGeneral}>Submit</button> : <div></div>}
                    </div>
                    {/***********************THIS IS CORRECT UP TO A POINT TO BE IMPLEMENTED ON VERSION 2, 3 ****************/}
                    {/* <div className="widget_PnU">
                        <div className="smartWallDiv">
                            <SmartWall showMeTheClass={showMeTheClass} isActive={isActive} isActive2={isActive2} isActive3={isActive3} isActive4={isActive4} isActive5={isActive5} isActive6={isActive6} />
                        </div>
                        <div className="radioGroup">
                            <FormControl component="fieldset" sx={{ color: 'rgb(56, 191, 191)' }}>
                                <FormLabel component="legend" sx={{ color: 'rgb(56, 191, 191)' }}>{radioLabel}</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" sx={{ color: 'rgb(56, 191, 191)' }} onChange={handleRadioChange}>
                                    {numberOfOptions > 0 ? <><FormControlLabel value={radioOption1} control={<Radio />} label={radioOption1} />
                                        <FormControlLabel value={radioOption2} control={<Radio />} label={radioOption2} /></> : <></>}
                                    {numberOfOptions === 3 ? <FormControlLabel value={radioOption3} control={<Radio />} label={radioOption3} /> : <></>}
                                    {numberOfOptions === 5 ? <><FormControlLabel value={radioOption3} control={<Radio />} label={radioOption3} /><FormControlLabel value={radioOption4} control={<Radio />} label={radioOption4} />
                                        <FormControlLabel value={radioOption5} control={<Radio />} label={radioOption5} /></> : <></>}
                                </RadioGroup>
                            </FormControl>
                            <div className="button_sub">
                                {hide ? <button className="subBut" onClick={submit_label_choise}>Submit</button> : <></>}
                            </div>
                        </div>
                    </div>
                    <div className="widget_KPI" >
                        {cardList.map((card, index) => (
                            <div style={{ width: "100%" }} key={index} onClick={() => removeCard(index)}>
                                {card}
                            </div>))}
                    </div> */}
                </div>
                {/* PRIMEREACT PART */}
                {tableToRender !== "ALL" ? <DataTable scrollHeight="600px" selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => alertingOnClick(e)} headerColumnGroup={headerGroup} stripedRows size="small" className="data_table" header={header1} emptyMessage="No data match your query...." first={first1} rows={rows1} onPage={onCustomPage1} paginator paginatorTemplate={template1} removableSort showGridlines value={data} sortMode="multiple" responsiveLayout="scroll" style={{ minHeight: '600px', margin: '5px' }}>
                    {columnComponents}
                </DataTable> :
                    <DataTable scrollHeight="600px" selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => alertingOnClick(e)} headerColumnGroup={headerGroupAll} stripedRows size="small" className="data_table" header={header1} emptyMessage="No data match your query...." first={first1} rows={rows1} onPage={onCustomPage1} paginator paginatorTemplate={template1} removableSort showGridlines value={data} sortMode="multiple" responsiveLayout="scroll" style={{ minHeight: '600px', margin: '5px' }}>
                        {columnComponents}
                    </DataTable>}
            </div>
        </div >

    )
}


export default Modest;
